import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VNavigationDrawer,{attrs:{"app":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c(VBtn,{attrs:{"block":"","color":"red","dark":""},on:{"click":_vm.signOut}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-lock")]),_vm._v("Keluar ")],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"dense":"","nav":""}},[_c(VListItem,[_c(VListItemAvatar,{attrs:{"color":"white"}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-account-circle")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.user.name))])],1)],1),_c(VDivider),_c(VListItem,{attrs:{"color":"blue","to":"/"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-home")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Home")])],1)],1),_vm._l((_vm.menus),function(item){return _c(VListGroup,{key:item.id,attrs:{"prepend-icon":item.module_icon,"no-action":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.module_name))])],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},_vm._l((item.menus),function(subItem){return _c(VListItem,{key:subItem.id,attrs:{"to":subItem.menu_route,"color":"blue"}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(subItem.menu_name))])],1),_c(VListItemIcon,[_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(subItem.menu_icon))])],1)],1)}),1)})],2)],1),_c('alert'),_c(VMain,[_c(VSlideYTransition,[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }